import React, { useState } from "react"
import Header from "./Header"
import Footer from "./Footer"
import { Link } from "gatsby"
import * as styles from "./index.module.scss"

const Layout = ({ children }) => {
  const [mobileMenu, setMobileMenu] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu)
  }

  return (
    <div className={"web-container"}>
      <Header toggleMobileMenu={toggleMobileMenu} mobileMenu={mobileMenu} />
      {mobileMenu ? (
        <MobileMenu close={() => setMobileMenu(false)} />
      ) : (
        <>
          <main>{children}</main>
          <Footer />
        </>
      )}
    </div>
  )
}

const MobileMenu = ({ close = () => null }) => {
  return (
    <div className={styles.mobile_menu}>
      <div className="container">
        <ul>
          <MenuLinks close={close} />
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/cart"
              activeClassName={"active"}
              onClick={close}
            >
              Cart
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export const MenuLinks = ({ close = () => null }) => {
  return (
    <>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/"
          activeClassName={"active"}
          onClick={close}
        >
          Home
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/our-farms"
          activeClassName={"active"}
          onClick={close}
        >
          Our Farms
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/about-us"
          activeClassName={"active"}
          onClick={close}
        >
          About us
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/contact-us"
          activeClassName={"active"}
          onClick={close}
        >
          Contact
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/organic-store"
          activeClassName={"active"}
          onClick={close}
        >
          Organic Store
        </Link>
      </li>
    </>
  )
}

export default Layout
