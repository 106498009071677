import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from "./types"

const items =
  typeof window !== "undefined"
    ? window.localStorage.getItem("cart_items")
    : null

const parsedItems = items ? JSON.parse(items) : null

const blankState = {
  items: [],
}

export const initialState = {
  items: parsedItems ? parsedItems.items : [],
}

let reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let newState = state
      const duplicate = state.items.find(item => item.id === action.payload.id)

      if (duplicate) {
        const index = newState.items.indexOf(duplicate)
        newState.items.splice(index, 1)
        newState = { ...state, items: [...state.items, action.payload] }
      } else {
        newState = { ...state, items: [...state.items, action.payload] }
      }
      if (typeof window !== "undefined") {
        window.localStorage.setItem("cart_items", JSON.stringify(newState))
      }

      return newState

    case REMOVE_FROM_CART:
      let cartToBeUpdated = state

      const itemToBeRemoved = state.items.find(
        item => item.id === action.payload
      )

      const index = cartToBeUpdated.items.indexOf(itemToBeRemoved)

      cartToBeUpdated.items.splice(index, 1)

      if (typeof window !== "undefined") {
        window.localStorage.setItem(
          "cart_items",
          JSON.stringify(cartToBeUpdated)
        )
      }

      cartToBeUpdated = { ...cartToBeUpdated }

      return cartToBeUpdated

    case CLEAR_CART:
      if (
        typeof window !== "undefined" &&
        process.env.NODE_ENV === "production"
      ) {
        try {
          window.localStorage.clear()
        } catch (e) {
          console.log(e)
        }
      }
      return blankState

    default:
      return { ...state }
  }
}

export default reducer
