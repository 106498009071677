import { SET_PRODUCTS, SET_PRODUCT_CATEGORIES } from "./types"

export const initialState = {
  products: [
    {
      id: 1,
      name: "Organic Suwandel Rice - 1kg",
      selling_price: 6,
      product_category_id: "1",
      product_category: {
        id: 2,
        name: "Organic Rice",
        slug: "organic-rice",
      },
    },
    {
      id: 2,
      name: "Organic Brown Rice - 1kg",
      selling_price: 5.9,
      product_category_id: "1",
      product_category: {
        id: 2,
        name: "Organic Rice",
        slug: "organic-rice",
      },
    },
    {
      id: 3,
      name: "Organic Black Tea - 100g",
      selling_price: 2,
      product_category_id: "1",
      product_category: {
        id: 1,
        name: "Organic Tea",
        slug: "organic-tea",
      },
    },
    {
      id: 4,
      name: "Organic Green Tea - 100g",
      selling_price: 2,
      product_category_id: "1",
      product_category: {
        id: 1,
        name: "Organic Tea",
        slug: "organic-tea",
      },
    },
  ],
  productCategories: [
    {
      id: 1,
      name: "Organic Tea",
      slug: "organic-tea",
    },
    {
      id: 2,
      name: "Organic Rice",
      slug: "organic-rice",
    },
  ],
}

let reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return { ...state, products: action.payload }
    case SET_PRODUCT_CATEGORIES:
      return { ...state, productCategories: action.payload }
    default:
      return { ...state }
  }
}

export default reducer
