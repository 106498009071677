import { SET_PRODUCTS, SET_PRODUCT_CATEGORIES } from "./types"

const useActions = (prevState, dispatch) => {
  const actions = {
    setProducts: payload => {
      dispatch({ type: SET_PRODUCTS, payload })
    },
    setProductCategories: payload => {
      dispatch({ type: SET_PRODUCT_CATEGORIES, payload })
    },
  }
  return actions
}

export default useActions
