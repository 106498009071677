import * as React from "react"
import PropTypes from "prop-types"
import useActions from "./actions"

import reducer, { initialState } from "./reducer"

let StoreContext = React.createContext({
  store: initialState,
  actions: {
    setProducts: () => null,
    setProductCategories: () => null,
  },
})

function StoreContextProvider({ children }) {
  let [store, dispatch] = React.useReducer(reducer, initialState)

  const actions = useActions(store, dispatch)

  return (
    <StoreContext.Provider value={{ store, actions }}>
      {children}
    </StoreContext.Provider>
  )
}

StoreContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

let StoreContextConsumer = StoreContext.Consumer

export { StoreContext, StoreContextProvider, StoreContextConsumer }
