import React from "react"
import Layout from "./src/Layout"
import { ToastContainer, toast, Flip } from "react-toastify"

import { CartContextProvider } from "./src/contexts/Cart"
import { StoreContextProvider } from "./src/contexts/Store"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./src/styles/theme/index.scss"
import "react-toastify/dist/ReactToastify.css"
import "./src/styles/base.scss"

export const wrapRootElement = ({ element }) => (
  <CartContextProvider>
    <StoreContextProvider>
        <ToastContainer theme={'light'} autoClose={5000} hideProgressBar position={toast.POSITION.TOP_CENTER} transition={Flip}/>
      <Layout>
          {element}
      </Layout>
    </StoreContextProvider>
  </CartContextProvider>
)

